import { Link } from 'react-router-dom';
import { InviteData, Language, createInviteData } from './invites';

const invites: InviteData[] = [
	{
		language: Language.English,
		names: "Mathias and Yuki"
	},
	{
		language: Language.English,
		names: "Hugh and Kumi"
	},
	{
		language: Language.English,
		names: "Garden"
	},
	{
		language: Language.English,
		names: "Wafel"
	},
	{
		language: Language.English,
		names: "Dale Ogden"
	},
	{
		language: Language.English,
		names: "Zachariah Hernandez"
	},
	{
		language: Language.English,
		names: "Robert Andrew Duncan"
	},
	{
		language: Language.English,
		names: "Lucien and Kanae Koefoed"
	},
	{
		language: Language.English,
		names: "Daniel Hay"
	},
	{
		language: Language.English,
		names: "Liam Thomas"
	},
	{
		language: Language.Russian,
		names: "Ивана и Александру Матвеевых"
	},
	{
		language: Language.Russian,
		names: "Марину и Дарью Викуловых"
	},
	{
		language: Language.Russian,
		names: "Лидью Петровну Матвееву"
	},
	{
		language: Language.Russian,
		names: "Виктора и Наталию Матвеевых"
	},
	{
		language: Language.Japanese,
		names: "Kouichi and Kazuko Tamura"
	},
	{
		language: Language.Japanese,
		names: "bnkrg"
	},
	{
		language: Language.Japanese,
		names: "Kouichi and Kazuko Tamura"
	},
	{
		names: "Akita family",
		language: Language.Japanese
	},
	{
		names: "Masahiro and Nobuko Akita",
		language: Language.Japanese
	},
	{
		names: "Masakazu and Reiko Tamura",
		language: Language.Japanese
	},
	{
		names: "Yuzu, Moemi, Wakana, Minami, Nagi and Sae",
		language: Language.Japanese
	},
	{
		names: "Momo",
		language: Language.Japanese
	},
	{
		names: "Anamin",
		language: Language.Japanese
	},
	{
		names: "Nan-chan",
		language: Language.Japanese
	},
	{
		names: "Mie-chan",
		language: Language.Japanese
	},
	{
		names: "Ayane and Shouta Morioka",
		language: Language.Japanese
	},
	{
		names: "Hiroshi and Etsuko Ohi",
		language: Language.Japanese
	},
	{
		names: "Yumin, Mariko, Eun-chan and Yoshiki-san",
		language: Language.Japanese
	},
	{
		names: "Risa",
		language: Language.Japanese
	},
]

function Invites() {
	const codes = invites.reduce((total, data) => (total[data.names] = createInviteData(data), total), {} as Record<string, string>)
	return <div className='links'>
		{invites.map(data => <div className='invite' key={data.names}>
			<div className="name"><Link to={`/${codes[data.names]}`}>{data.names}</Link></div>
			<div className="copy" onClick={() => navigator.clipboard?.writeText(`${window.location.protocol}//${window.location.host}/${codes[data.names]}`)}>Copy</div>
		</div>)
		}
	</div >
}

export default Invites;
