import { useLocation } from 'react-router-dom';
import ImageSlider from './ImageSlider';
import { Divider, DividerType } from './Divider';
import clsx from 'clsx';
import { Language, getInviteData } from './invites';

// First, checks if it isn't implemented yet.
function format(s: string, ...values: (string | undefined)[]) {
  return s.replace(/{(\d+)}/g, (match, number) =>
    values[number] != null
      ? values[number]!
      : match.toString()
  );
};

interface LanguageMap extends Record<string, LanguageMap | string> { }

const languageString: Record<Language, LanguageMap> = {
  [Language.English]: {
    header: {
      start: 'Kaori and Denis invite',
      end: 'To join their wedding celebration',
    },
    date: '5th of April 2025',
    heian: {
      title: 'Wedding Ceremony',
      subtitle: '10am - 12pm, Heian Jingu, Kyoto',
      description: 'Traditional Japanese wedding ceremony.',
      after: {
        subtitle: '12pm - 4:30pm, Heian Jingu, Kyoto',
        description: 'Reception and banquet with garden view.'
      },
    },
    bar: {
      title: 'After Party',
      subtitle: '5pm, Pig & Whistle, Kyoto',
      description: 'Open bar at public house Pig & Whistle'
    },
  },
  [Language.Japanese]: {
    header: {
      start: 'Kaori and Denis invite',
      end: 'To join their wedding celebration',
    },
    date: '5th of April 2025',
    heian: {
      title: 'Wedding Ceremony',
      subtitle: '10時〜12時、平安神宮京都',
      description: '神前結婚',
      after: {
        subtitle: '12時〜16時30分、平安神宮京都',
        description: '結婚披露宴'
      },
    },
    bar: {
      title: 'After Party',
      subtitle: '17時〜, ピッグ＆ホイッスル京都',
      description: '無料飲み放題とおつまみ付きパーティー'
    },
  },
  [Language.Russian]: {
    header: {
      start: 'Каори и Денис приглашают',
      end: 'Отметить их свадьбу',
    },
    date: 'Пятого Апреля 2025 Года',
    heian: {
      title: 'Свадьба',
      subtitle: 'С 10 утра до 12 часов дня в Хэйан-дзингу, Киото, Япония',
      description: 'Традиционная японская церемония бракосочетания в храме.',
      after: {
        subtitle: 'С 12 дня до 4:30 дня в Хэйан-дзингу, Киото, Япония',
        description: 'Свадебный банкет.'
      },
    },
    bar: {
      title: 'Вечеринка',
      subtitle: 'С 5 часов вечера в Pig & Whistle, Киото',
      description: 'Вечеринка в баре Pig & Whistle включая закуски и алкогольные напитки.'
    },
  }
}

function useLanguage(lang: Language): (key: string, ...values: (string | undefined)[]) => string {
  return (key, ...values) => {
    const keyParts = key.split('.')
    let rec = languageString[lang]
    do {
      rec = rec[keyParts.shift()!] as LanguageMap
    } while (keyParts.length)
    return format(rec as any as string, ...values)
  }
}

function App() {
  const location = useLocation()
  const data = getInviteData(location.pathname)
  const lang = data?.language ?? Language.English
  const i18n = useLanguage(lang)

  return (
    <div className={clsx("body", Language[lang])}>
      <div className="section invitation">
        <Divider type={DividerType.Heart} />
        <div className="split" />
        <div className="header">
          {i18n('header.start')}
          <div className="names">{data?.names}</div>
          {i18n('header.end')}
        </div>
        <div className="split" />
        <Divider type={DividerType.Heart} />
      </div>

      <div className="section top">
        {/* <Divider type={DividerType.Heart} negative /> */}
        <ImageSlider
          className='vertical'
          images={[
            `${process.env.PUBLIC_URL}/us/DSC_5263.fit.jpg`,
            `${process.env.PUBLIC_URL}/us/DSC_5254.fit.jpg`,
            `${process.env.PUBLIC_URL}/us/DSC_5367.fit.jpg`,
            `${process.env.PUBLIC_URL}/us/DSC_5109.fit.jpg`,
            `${process.env.PUBLIC_URL}/us/DSC_5257.fit.jpg`,
          ]}
        />
      </div>
      <Divider type={DividerType.Cube} />

      <div className="date" dangerouslySetInnerHTML={{ __html: i18n('date') }} />
      <div className="venue">
        <img draggable="false" src={`${process.env.PUBLIC_URL}/heian-jingu-resize.jpg`} />
        <div className="title">{i18n('heian.title')}</div>
        <div className="subtitle">{i18n('heian.subtitle')}</div>
        <div className="description continue">{i18n('heian.description')}</div>

        <div className="subtitle">{i18n('heian.after.subtitle')}</div>
        <div className="description">{i18n('heian.after.description')}</div>

        <div className="map">

          <iframe
            title='map-small'
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3267.6293530668786!2d135.7824263!3d35.0159823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600108e5187cc88d%3A0x75bed992d897454f!2sHeian%20Shrine!5e0!3m2!1sen!2sjp!4v1732029380922!5m2!1sen!2sjp"
            allowFullScreen={false}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <Divider type={DividerType.Cube} />
      <div className="venue">
        <img draggable="false" src={`${process.env.PUBLIC_URL}/pig-whistle-resize.jpg`} />
        <div className="title" >{i18n('bar.title')}</div>
        <div className="subtitle">{i18n('bar.subtitle')}</div>
        <div className="description">{i18n('bar.description')}</div>
        <div className="map">
          <iframe
            title='map-bar-small'
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8000.176817178586!2d135.77210786353106!3d35.00912932833365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600108ec835fa07f%3A0x204bb2603a88955f!2sPig%20%26%20Whistle!5e0!3m2!1sen!2sjp!4v1732036666265!5m2!1sen!2sjp"
            allowFullScreen={false}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <Divider type={DividerType.Cube} />
      <div className="section bottom">
        <ImageSlider
          className='horizontal'
          images={[
            `${process.env.PUBLIC_URL}/us/DSC_5090.fit.jpg`,
            `${process.env.PUBLIC_URL}/us/DSC_5142.fit.jpg`,
            `${process.env.PUBLIC_URL}/us/DSC_5251.fit.jpg`,
            `${process.env.PUBLIC_URL}/us/DSC_5349.fit.jpg`,
          ]}
        />
      </div>
      <Divider type={DividerType.Heart} className="last" />
    </div >
  );
}

export default App;
